import { createI18n } from "vue-i18n";
import da from "./da.json";
import de from "./de.json";
import en_US from "./en_US.json";
import es from "./es.json";
import fi from "./fi.json";
import fr from "./fr.json";
import fr_CA from "./fr_ca.json";
import it from "./it.json";
import nl from "./nl.json";
import no from "./no.json";
import pt_BR from "./pt_br.json";
import pt_PT from "./pt_pt.json";
import sv from "./sv.json";

export const i18n = createI18n({
  locale: localStorage.getItem("locale") as string,
  globalInjection: true,
  legacy: false,
  runtimeOnly: true,
  fallbackLocale: "en_US",
  messages: {
    en_US,
    es,
    de,
    fr_CA,
    fr,
    pt_PT,
    pt_BR,
    it,
    nl,
    sv,
    da,
    no,
    fi,
  },
});
