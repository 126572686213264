import axios, { AxiosRequestConfig } from "axios";

import config from "@/config";
import { useAppStore } from "@/store/app";
import { BlogSiteService } from "./BlogSiteService";
import { BlogsListService } from "./BlogsListService";
import { EditorService } from "./EditorService";

const getHeader = () => {
  return {
    channel: "APP",
    source: "WEB_USER",
    version: "2021-07-28",
  };
};

export const requests = (host?: string) => {
  const baseUrl = host || config.REST_API_URLS;
  const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: getHeader(),
  });

  axiosInstance.interceptors.request.use(async (requestConfig: AxiosRequestConfig) => {
    try {
      const appStore = useAppStore();

      if (requestConfig && requestConfig.headers && appStore.token) {
        requestConfig.headers["token-id"] = appStore.token;
      }
    } catch (e) {}
    return requestConfig;
  });
  return axiosInstance;
};

export { BlogSiteService, BlogsListService, EditorService };
