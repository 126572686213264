import blogHome from "@/blog-home.json";
import blogPost from "@/blog-post.json";
import { FunnelService } from "@/services/FunnelService";

export async function buildPage(funnel, funnelId, locationId) {
  const steps = funnel.steps;
  const homeStep = steps.find((i) => i.type === "blog-home");
  const postStep = steps.find((i) => i.type === "blog-post");

  const home = blogHome;
  home.sections[0].funnelId = funnelId;
  home.sections[0].locationId = "" + locationId;
  home.sections[0].pageId = homeStep.pages[0];

  const post = blogPost;
  post.sections[0].funnelId = funnelId;
  post.sections[0].locationId = "" + locationId;
  post.sections[0].pageId = postStep.pages[0];

  await savePageData(funnelId, home, homeStep.pages[0]);
  await savePageData(funnelId, post, postStep.pages[0]);
  return true;
}

async function savePageData(funnelId, pageData, pageId) {
  const obj = {
    pageData,
    funnelId,
    pageVersion: 1,
  };
  const { data } = await FunnelService.savePageData(pageId, obj);
  await FunnelService.updateFunnelPage({
    pageId,
    payload: {
      pageDataDownloadUrl: data.pageDataDownloadUrl,
      pageDataUrl: data.pageDataUrl,
    },
  });
}
