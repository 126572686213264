import {
  BlogImportLinks,
  BlogSiteListByLocation,
  BlogsMetricsParams,
  CreateBlog,
  CreateImportBlogPost,
  CreateImportPost,
  FetchBlogPage,
  FunnelList,
  FunnelListByLocation,
  GetBlogByIdParams,
  UpdateImport,
} from "@/types";

import config from "@/config";
import { requests } from ".";

export const BlogSiteService = {
  getDefaultBlogSite: (locationId: string) =>
    requests().get(`/blogs/site/blog-default`, {
      params: { locationId },
    }),
  getBlogsByLocation: (params: BlogSiteListByLocation) =>
    requests().get(`/blogs/site/list`, { params }),
  GetBlogById: (params: GetBlogByIdParams) => requests().get(`/blogs/site/fetch`, { params }),
  GetUniqueFunnelDomain: (params: FunnelListByLocation) =>
    requests().get(`/blogs/site/unique-funnel-domain`, { params }),
  getMigrationStatus: (locationId: string) =>
    requests().get<{ isMigrated: boolean }>(`/blogs/site/migration-status`, {
      params: { locationId },
    }),
  domainListRss: (locationId: string) =>
    requests().get(`/blogs/site/domain-list-rss`, {
      params: { locationId },
    }),
  migrateBlogPost: (params: CreateBlog) =>
    requests().post<{ success: boolean }>(`/blogs/site/migrate-blog-post`, params),
  getBlogsMetrics: (params: BlogsMetricsParams) =>
    requests().get(`/blogs/posts/metrics`, { params }),
  createBlog: (params: CreateBlog) => requests().post(`/blogs/site/create`, params),
  deleteBlogById: (blogId: string, locationId: string) =>
    requests().delete(`/blogs/site/${locationId}/${blogId}`),
  fetchFunnelListWithDomain: (params: FunnelListByLocation) =>
    requests(config.FUNNEL_SERVICE).get<{ pages: FunnelList[] }>(`/lookup/funnel-with-domain`, {
      params,
    }),
  fetchBlogPage: (params: FetchBlogPage) => requests().post(`/blogs/site/fetch-blog-page`, params),
  createImportPost: (params: CreateImportPost) =>
    requests().post(`/blogs/site/create-import`, params),
  createImportPostById: (id: string) => requests().get(`/blogs/site/import/${id}`),
  updateImport: (importId: string, params: UpdateImport) =>
    requests().put(`/blogs/site/import/${importId}`, params),
  getAllImportLinks: (params: BlogImportLinks) =>
    requests().get(`/blogs/site/imports/all`, { params }),
  createImportedBlogPost: (importId: string, params: CreateImportBlogPost) =>
    requests().put(`/blogs/site/create/import/${importId}`, params),
  refreshImportBlogPost: (importId: string, params: UpdateImport) =>
    requests().put(`/blogs/site/refresh/import/${importId}`, params),
};
