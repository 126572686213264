import isNumber from "lodash-es/isNumber";
import memoize from "lodash-es/memoize";

const parseSizeToNumber = (value: string) => {
  if (/(\d.*)px/.test(value)) {
    return Number(value.replace("px", ""));
  } else if (isNumber(parseSizeToNumber)) {
    return value;
  }

  return undefined;
};

const getImageDimensions = (src: string) => {
  if (!src) {
    return undefined;
  }

  return new Promise<{ height: number; width: number }>((resolve, reject) => {
    const img = new Image();
    img.onload = () =>
      resolve({
        height: img.height,
        width: img.width,
      });
    img.onerror = reject;
    img.src = src;
  });
};

// returns in bytes = 10^(-6) MB
const getResourceSize = memoize((src: string) => {
  return new Promise<{ resourceDetails: Blob }>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", src, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      resolve({ resourceDetails: xhr.response as Blob });
    };
    xhr.onerror = reject;
    xhr.send();
  });
});

export { getImageDimensions, getResourceSize, parseSizeToNumber };
