interface Config {
  mode: string;
  REST_API_URLS: string;
  mediaCenterUrl: string;
  USER_SERVICE: string;
  FUNNEL_SERVICE: string;
  RSS_URL: string;
  contentAIServiceUrl: string;
}

const config: { [key: string]: Config } = {
  development: {
    mode: "dev",
    REST_API_URLS: "https://staging.services.msgsndr.com",
    mediaCenterUrl: "https://hl-media-center.web.app",
    USER_SERVICE: "https://staging.services.msgsndr.com/users",
    FUNNEL_SERVICE: "https://staging.services.msgsndr.com/funnels",
    RSS_URL: "http://localhost:5092/blogs/posts/rss",
    contentAIServiceUrl: "https://staging.services.leadconnectorhq.com/content",
  },
  staging: {
    mode: "staging",
    REST_API_URLS: "https://staging.services.msgsndr.com",
    mediaCenterUrl: "https://hl-media-center.web.app",
    USER_SERVICE: "https://staging.services.leadconnectorhq.com/users",
    FUNNEL_SERVICE: "https://staging.services.msgsndr.com/funnels",
    RSS_URL: "https://staging.rss-link.com/feed",
    contentAIServiceUrl: "https://staging.services.leadconnectorhq.com/content",
  },
  production: {
    mode: "production",
    REST_API_URLS: "https://services.leadconnectorhq.com",
    mediaCenterUrl: "https://media-center-prod.web.app",
    USER_SERVICE: "https://services.leadconnectorhq.com/users",
    FUNNEL_SERVICE: "https://services.leadconnectorhq.com/funnels",
    RSS_URL: "https://rss-link.com/feed",
    contentAIServiceUrl: "https://services.leadconnectorhq.com/content",
  },
};

const override = false;

const overrideConfig = {
  override: true,
};
//@ts-ignore
const envConfig = config[process.env.NODE_ENV];

export const mediaUploadLimitsInBytes = {
  onBlogCreate: 10000000,
  forAuthorImage: 10000000,
  forCategoryImage: 10000000,
  forEditorImageEmbed: 30000000,
  forEditorVideoEmbed: 50000000,
  forEditorAudioEmbed: 50000000,
};

// 1 MB = 10^6 Bytes
export const convertBytesToMb = (limitFor: number, round = true) => {
  if (round) {
    return Math.round(limitFor * Math.pow(10, -6));
  }
  return (limitFor * Math.pow(10, -6)).toFixed(1);
};

export default override ? { ...envConfig, ...overrideConfig } : envConfig;
export * from "./fonts";
export * from "./identifier";
export * from "./postmateEmits";
