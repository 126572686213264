<script setup lang="ts">
import { UISpin } from "@gohighlevel/ghl-ui";
import { onMounted, ref } from "vue";
import { BlogSiteService } from "./services";
import { FunnelService } from "./services/FunnelService";
import { useAppStore } from "./store/app";

import { buildPage } from "./util/blog";

const store = useAppStore();
const showLoader = ref(true);

const checkMigrationStatus = async () => {
  try {
    showLoader.value = true;
    const { data } = await BlogSiteService.getMigrationStatus(store.locationId);
    const isMigrated = data?.isMigrated ?? true;
    if (isMigrated) {
      showLoader.value = false;
      return;
    }
    const body = {
      name: "Default Blog Site",
      type: "blog",
      locationId: store.locationId,
      homePath: "home-blog",
    };
    let { data: as } = await FunnelService.createBlog(body);

    // Add a 3 second delay here, firebase ---> mongodb
    await new Promise((resolve) => setTimeout(resolve, 6000));

    await buildPage(as.result.data._data, as.funnelId, store.locationId);
    const blog = {
      funnelId: as.funnelId,
      locationId: store.locationId,
    };
    await BlogSiteService.migrateBlogPost(blog);
    showLoader.value = false;
  } catch (err) {
    console.error(err);
    showLoader.value = false;
  }
};

onMounted(() => {
  checkMigrationStatus();
});
</script>
<template>
  <div v-if="showLoader" class="flex h-screen p-80">
    <div class="m-auto">
      <UISpin size="large" />
    </div>
  </div>
  <div v-else>
    <RouterView />
  </div>
</template>
