import "@/assets/styles/editor.scss";
import "@/assets/styles/index.css";
import "@gohighlevel/ghl-ui/dist/style.css";
import "@iconify/iconify";
import "emoji-mart-vue-fast/css/emoji-mart.css";
import "remixicon/fonts/remixicon.css";
import "vue-color-kit/dist/vue-color-kit.css";

import App from "@/App.vue";
import mitt from "mitt";
import { createPinia } from "pinia";
import { createApp } from "vue";
import { i18n } from "./locales";
import { default as index, default as router } from "./router";
import { setupSentry } from "./util/setupSentry";

const emitter = mitt();

const app = createApp(App);
app.config.globalProperties.emitter = emitter;
setupSentry(app, router, process.env.NODE_ENV as string);
app.use(createPinia());
app.use(index);
app.use(i18n);
app.mount("#app");
