import { createRouter, createWebHistory } from "vue-router";

import { localRoutes } from "./local-routes";

const router = createRouter({
  history: createWebHistory(),
  routes: localRoutes,
});

export default router;
