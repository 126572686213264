export enum LanguageKeys {
  EnglishUS = "en_US",
  Danish = "da",
  Dutch = "nl",
  Finnish = "fi",
  French = "fr-FR",
  FrenchCanada = "fr-CA",
  German = "de",
  Italian = "it",
  Norwegian = "no",
  Portuguese = "pt-PT",
  PortugueseBrazil = "pt-BR",
  Spanish = "es",
  Swedish = "sv",
}
