import { getCurrentInstance } from "vue";

export const emiterFor = {
  fetchSelectedImage: "fetchSelectedImage",
  fetchSelectedContent: "fetchSelectedContent",
  insertSelectedContent: "insertSelectedContent",
  onContentAIInsertion: "onContentAIInsertion",
  emitQuickActions: "emitQuickActions",
  fetchSelectedQuickActionsContent: "fetchSelectedQuickActionsContent",
};

export function useEmitter() {
  const internalInstance = getCurrentInstance();
  const emitter = internalInstance?.appContext.config.globalProperties.emitter;

  return emitter;
}
