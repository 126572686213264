const baseRoute = "/location/:locationId/blogs";

export default {
  BlogSiteList: `${baseRoute}`,
  BlogsList: `${baseRoute}/site/:blogId`,
  NewBlog: `${baseRoute}/site/create/new`,
  Settings: `${baseRoute}/settings`,
  BlogSetting: `${baseRoute}/site/:blogId/setting`,
  BlogImport: `${baseRoute}/site/:blogId/import`,
  BlogImportId: `${baseRoute}/site/:blogId/import/:importId`,
  Authors: `${baseRoute}/settings/authors`,
  EditAuthor: `${baseRoute}/settings/authors/:authorId/edit`,
  Categories: `${baseRoute}/settings/categories`,
  EditCategory: `${baseRoute}/settings/categories/:categoryId/edit`,
  BlogPost: `${baseRoute}/post/:postId`,
  BlogPostEditor: `${baseRoute}/post/:postId/edit`,
  BlogPostSettings: `${baseRoute}/post/:postId/settings`,
  BlogPostPreview: `${baseRoute}/post/:postId/preview`,
  BlogPostVersions: `${baseRoute}/post/:postId/versions`,
  FunnelDomains: "/location/:locationId/settings/domain",
  FunnelBuilder: "/location/:locationId/blog-builder/:pageId",
};
