import { RouteRecordRaw } from "vue-router";
import Routes from "./paths";
export const localRoutes: RouteRecordRaw[] = [
  {
    path: Routes.NewBlog,
    name: Routes.NewBlog,
    component: () => import("@/pages/NewBlog.vue"),
  },
  {
    path: Routes.BlogImport,
    name: Routes.BlogImport,
    component: () => import("@/pages/BlogImport.vue"),
  },
  {
    path: Routes.BlogImportId,
    name: Routes.BlogImportId,
    component: () => import("@/pages/BlogImport.vue"),
  },
  {
    path: Routes.BlogSiteList,
    name: Routes.BlogSiteList,
    component: () => import("@/pages/BlogSiteList.vue"),
  },
  {
    path: Routes.BlogsList,
    name: Routes.BlogsList,
    component: () => import("@/pages/BlogsList.vue"),
  },
  {
    path: Routes.BlogSetting,
    name: Routes.BlogSetting,
    component: () => import("@/pages/BlogSetting.vue"),
  },
  {
    path: Routes.Authors,
    redirect: Routes.Settings,
  },
  {
    path: Routes.Categories,
    redirect: Routes.Settings,
  },
  {
    name: Routes.Settings,
    path: Routes.Settings,
    component: () => import("@/pages/Settings.vue"),
  },
  {
    name: Routes.BlogPostEditor,
    path: Routes.BlogPostEditor,
    component: () => import("@/pages/BlogPostEditor.vue"),
  },
  {
    name: Routes.BlogPostSettings,
    path: Routes.BlogPostSettings,
    component: () => import("@/pages/BlogPostSettings.vue"),
  },
  {
    name: Routes.BlogPostPreview,
    path: Routes.BlogPostPreview,
    component: () => import("@/pages/BlogPostPreview.vue"),
  },
  {
    name: Routes.BlogPostVersions,
    path: Routes.BlogPostVersions,
    component: () => import("@/pages/VersionHistory.vue"),
  },
];
